import { lazy } from 'react'

// project imports
import Loadable from '../ui-component/Loadable'
import MinimalLayout from '../layout/MinimalLayout'

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('../views/pages/authentication/authentication3/Login3')))
const AuthRegister3 = Loadable(lazy(() => import('../views/pages/authentication/authentication3/Register3')))
const PickApp = Loadable(lazy(() => import('../views/pages/pick/App')))
const Logout = Loadable(lazy(() => import('../views/pages/authentication/auth-forms/Logout')))
const Recovery = Loadable(lazy(() => import('../views/pages/authentication/authentication3/Recovery')))
const Recover = Loadable(lazy(() => import('../views/pages/authentication/authentication3/Recover')))
const ChangePassword = Loadable(lazy(() => import('../views/pages/security/ChangePassword')))
const OptionHome = Loadable(lazy(() => import('../views/pages/manager/options/Home')))
const OptionProfile = Loadable(lazy(() => import('../views/pages/manager/options/Profile')))
const UserHome = Loadable(lazy(() => import('../views/pages/manager/users/Home')))
const ProfileHome = Loadable(lazy(() => import('../views/pages/manager/profiles/Home')))
const OptionPick = Loadable(lazy(() => import('../views/pages/option/Pick')))
const SecondApp = Loadable(lazy(() => import('../views/pages/second/App')))

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin3 />
        },
        {
            path: '/register',
            element: <AuthRegister3 />
        },
        {
            path: '/pick',
            element: <PickApp />
        },
        {
            path: '/second',
            element: <SecondApp />
        },
        {
            path: '/logout',
            element: <Logout />
        },
        {
            path: '/recovery',
            element: <Recovery />
        },
        {
            path: '/recover',
            element: <Recover />
        },
        {
            path: '/change_password',
            element: <ChangePassword />
        },
        {
            path: '/option/home',
            element: <OptionHome />
        },
        {
            path: '/option/profile',
            element: <OptionProfile />
        },
        {
            path: '/user/home',
            element: <UserHome />
        },
        {
            path: '/option/pick',
            element: <OptionPick />
        },
        {
            path: '/profile/manager',
            element: <ProfileHome />
        }
    ]
}

export default AuthenticationRoutes